const translations = require('../../assets/configs/babel/en.json')
const _ = require('lodash')

const tmpBabel = require('../tmpBabel.json')
const tmpBabelKd = require('../tmpBabel-kd.json')

// sometimes webpacker in production doesn't rebulild babel
const forceRebuildToken = require('../forceBabelRebuild')

window.__localization = {
  locale: 'en',
  translations: _.merge({}, tmpBabel, tmpBabelKd, translations) 
}
